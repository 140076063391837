import PopupContainerComponent from '@wix/thunderbolt-elements/src/components/PopupContainer/viewer/PopupContainer';


const PopupContainer = {
  component: PopupContainerComponent
};


export const components = {
  ['PopupContainer']: PopupContainer
};

